import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RegistrationForm from '../components/RegistrationForm';
import DrawerSidebar from '../components/DrawerSidebar';
import { SearchInputField } from '../components/TextInputField';
import axios from 'axios';
import { statusBgColor, toNormalCase } from '../utils/constants';
import ContextMenu from '../components/ContextMenu';
import FilterComponent from '../components/FilterComponent';
import dayjs from 'dayjs';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PDFDocument from '../components/PDFDocument';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { SERVER_CONFIG, isAuthenticated } from '../utils/config';
import { DELETE_MEMBER, useMember } from '../context/MemberContext';
import toastify from '../components/Toastify';
import ConfirmationModal from '../components/ConfirmationModal';

const filters = [
  { label: 'Pending', value: 'PENDING' },
  { label: 'Fee Awaited', value: 'FEE AWAITED' },
  { label: 'Approved', value: 'APPROVED' },
  { label: 'Decline', value: 'DECLINE' },
  { label: 'All', value: 'ALL' },
];

function MembersList() {
  const { state, dispatch } = useMember();
  const [modalOpen, setModalOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [modalData, setModalData] = useState({});
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState(userData);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [ConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    fetchData();
    handleScrollEvent();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [page, limit, status]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${SERVER_CONFIG.SERVER_IP_WGGV}/api/v1/members?page=${page}&limit=${limit}&status=${status}&document=true`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch member data');
      }
      const newData = await response.json();
      await dispatch({ type: 'SET_MEMBERS', payload: newData.rows });
    } catch (error) {
      toastify('ERROR', 'Error fetching member data', 'alertmsg');
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setUserData(state?.members);
      setLoading(false);
    }, 1000);
  }, [state]);

  const handleScrollEvent = () => {
    window.addEventListener('scroll', handleScroll);
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 20 && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    // getListOfMembers();
    setFilteredData(userData);
  }, [userData]);

  const handleSearchChange = (event) => {
    const text = event;
    setSearchText(text);

    const filteredUserData = userData.filter(
      (item) =>
        item.name.toLowerCase().includes(text.toLowerCase()) ||
        item.cellNo.includes(text)
    );

    setFilteredData(filteredUserData);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontWeight: 'bold',
      fontSize: 15,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: theme.palette.common.black,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& > :first-child': {
      cursor: 'pointer',
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  //Delete start

  const handleCloseModal = () => {
    setConfirmationModalOpen(false);
  };

  const handleConfirmAction = async () => {
    // Implement your confirm action here
    try {
      // Call the delete API
      setDeleteLoading(true);
      const response = await axios.delete(
        `${SERVER_CONFIG.SERVER_IP_WGGV}/api/v1/member/${modalData.id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers as needed
          },
        }
      );

      if (response) {
        console.log('Delete API call successful');
        await dispatch({
          type: DELETE_MEMBER,
          payload: {
            id: modalData.id,
          },
        });
        toastify('SUCCESS', 'Form Deleted successfully!', 'alertmsg');
        // Close the modal after successful delete
        setDeleteLoading(false);
        handleCloseModal();
      } else {
        setDeleteLoading(false);
        toastify('ERROR', 'Failed to delete request', 'alertmsg');
        console.error('Delete API call failed');
      }
    } catch (error) {
      setDeleteLoading(false);
      toastify('ERROR', 'Failed to delete request', 'alertmsg');
      handleCloseModal();
      console.error('Error occurred while calling delete API:', error);
    }
  };
  //Delete end

  const handleAction = (itemInfo, actionLabel) => {
    switch (actionLabel) {
      case 'Edit':
        // Perform edit action
        console.log('Editing item:', itemInfo);
        setModalOpen(true);
        setModalData(itemInfo);
        break;
      case 'Delete':
        // Perform delete action
        setConfirmationModalOpen(true);
        setModalData(itemInfo);
        console.log('Deleting item:', itemInfo);

        break;
      case 'Approve':
        // Perform approve action
        console.log('Approving item:', itemInfo);
        break;
      case 'Decline':
        // Perform decline action
        console.log('Declining item:', itemInfo);
        break;
      // Add more cases for other actions as needed
      default:
        console.log(`Unknown action '${actionLabel}'`);
    }
  };

  const actions = [
    {
      label: 'Edit',
      handler: (itemInfo) => handleAction(itemInfo, 'Edit'),
    },
    {
      label: 'Delete',
      handler: (itemInfo) => handleAction(itemInfo, 'Delete'),
      disabled: false,
    },
    {
      label: 'Approve',
      handler: (itemInfo) => handleAction(itemInfo, 'Approve'),
      disabled: true,
    },
    {
      label: 'Decline',
      handler: (itemInfo) => handleAction(itemInfo, 'Decline'),
      disabled: true,
    },
    // Add more actions as needed
  ];

  const handleRowClick = (item) => {
    setModalOpen(true);
    setModalData(item);
  };

  const handleFilterChange = async (selectedFilter) => {
    console.log('Selected filter:', selectedFilter);
    await dispatch({ type: 'EMPTY_MEMBERS', payload: [] });
    if (selectedFilter.value === 'ALL' || selectedFilter === null) {
      setStatus('');
    } else {
      setStatus(selectedFilter.value);
    }
    await dispatch({
      type: 'SET_MEMBERS_STATUS',
      payload: selectedFilter.value,
    });
    setUserData([]);
    setPage(0);
  };

  //Download pdf
  const downloadPDF = () => {
    const blob = new Blob([<PDFDocument data={filteredData} />], {
      type: 'application/pdf',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'personal_information.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <DrawerSidebar>
      <div className='flex justify-between'>
        <div className='flex gap-4'>
          <div>
            <SearchInputField
              text={searchText}
              autoFocus={false}
              placeholder={'Search'}
              onSearchClick={(searchKey) => {}}
              handleChange={(e) => {
                handleSearchChange(e.target.value);
              }}
            />
          </div>
          <div className='w-[200px]'>
            <FilterComponent
              title={'Filter by'}
              data={filters}
              selectedValue={status}
              onSelection={handleFilterChange}
            />
          </div>
        </div>
        <div className='flex items-center gap-4'>
          <div>
            <Tooltip title='Coming soon'>
              <IconButton aria-label='download pdf'>
                <PdfIcon />
              </IconButton>
            </Tooltip>
          </div>
          {/* <div>
            <PDFDownloadLink
              document={<PDFDocument data={filteredData} />}
              fileName='members.pdf'
            >
              {({ loading }) =>
                loading ? (
                  <Tooltip title='Loading'>
                    <IconButton aria-label='download pdf'>
                      <PdfIcon />
                    </IconButton>
                  </Tooltip>
                ) : filteredData.length > 0 ? (
                  <Tooltip title='Download PDF'>
                    <IconButton aria-label='download pdf'>
                      <PdfIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title='No data found '>
                    <IconButton aria-label='download pdf'>
                      <PdfIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
            </PDFDownloadLink>
          </div> */}
          <div>
            <Tooltip title='Coming soon'>
              <IconButton aria-label='download excel'>
                <InsertDriveFileIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className='my-10'>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Date of birth</StyledTableCell>
                <StyledTableCell>Gender</StyledTableCell>
                <StyledTableCell>Phone number</StyledTableCell>
                <StyledTableCell>Membership Number</StyledTableCell>
                <StyledTableCell>Place of work / Institution</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.map((item) => (
                <StyledTableRow key={item?.name}>
                  <StyledTableCell
                    component='th'
                    scope='row'
                    onClick={() => handleRowClick(item)}
                  >
                    {item?.name}
                  </StyledTableCell>
                  <StyledTableCell>
                    {dayjs(item?.dateOfBirth).format('DD/MM/YYYY')}
                  </StyledTableCell>
                  <StyledTableCell>{item?.sex}</StyledTableCell>
                  <StyledTableCell>{item?.cellNo}</StyledTableCell>
                  <StyledTableCell>{item?.uniqueNo}</StyledTableCell>
                  <StyledTableCell>
                    {item?.institutionsOrOfficeName}
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      className={`${statusBgColor(
                        item?.status
                      )} w-fit rounded p-1 text-white`}
                    >
                      {toNormalCase(item?.status)}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <ContextMenu actions={actions} itemInfo={item} />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {!loading && userData.length === 0 && (
                <StyledTableRow>
                  <StyledTableCell colSpan={5}>
                    No Records found
                  </StyledTableCell>
                </StyledTableRow>
              )}
              {loading && (
                <StyledTableRow>
                  <StyledTableCell colSpan={5}>Loading...</StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <RegistrationForm
          data={modalData}
          onOpen={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
        />
        <ConfirmationModal
          isOpen={ConfirmationModalOpen}
          title='Confirm Action'
          message='Are you sure you want to delete this member?'
          onClose={handleCloseModal}
          onConfirm={handleConfirmAction}
          deleteLoading={deleteLoading}
        />
      </div>
    </DrawerSidebar>
  );
}

export default MembersList;
