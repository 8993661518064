import React, { useContext, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthContext from './AuthContext'; // Import your AuthContext
import { getLocalStorageItem } from './utils/localStorageUtils';

const PrivateRoute = ({ children }) => {
  const storedToken = getLocalStorageItem('accessToken');
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    storedToken && setIsAuthenticated(true);
  }, [storedToken]);

  if (!isAuthenticated && !storedToken) {
    // Redirect to login page (replace with your login route)
    return <Navigate to='/' replace />;
  }

  return children || <Outlet />; // Render children or nested routes
};

export default PrivateRoute;
