import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// const FilterComponent = ({ filters, handleFilterChange }) => {
//   const [selectedFilter, setSelectedFilter] = useState('');
//   const [anchorEl, setAnchorEl] = useState(null);

//   const handleChange = (event) => {
//     setSelectedFilter(event.target.value);
//     handleFilterChange(event.target.value);
//   };

//   return (
//     <Box sx={{ minWidth: 120 }}>
//       <FormControl fullWidth>
//         <InputLabel id='demo-simple-select-label'>Filter by</InputLabel>
//         <Select
//           labelId='demo-simple-select-label'
//           id='demo-simple-select'
//           value={selectedFilter}
//           label='Filter by'
//           style={{
//             borderRadius: 5,
//             border: 'none',
//             boxShadow: '0px 1px 0px 1px #ccc',
//             height: '2.5rem',
//           }}
//           onChange={handleChange}
//           MenuProps={{ PaperProps: { style: { maxHeight: '20rem' } } }}
//         >
//           {filters.map((filter, index) => (
//             <MenuItem key={index} value={filter.value}>
//               {filter.label}
//             </MenuItem>
//           ))}
//           {/* <MenuItem value={10}>Ten</MenuItem>
//           <MenuItem value={20}>Twenty</MenuItem>
//           <MenuItem value={30}>Thirty</MenuItem> */}
//         </Select>
//       </FormControl>
//     </Box>
//   );
//   {
//     /*<div className='w-full'>
//        <FormControl fullWidth>
//         <InputLabel >Filter</InputLabel>
//         <Select
//           id='filter-select'
//           value={selectedFilter}
//           onChange={handleChange}
//           style={{ borderRadius: 5, border: 'none', boxShadow: '0px 1px 0px 1px #ccc' }}
//           renderValue={(value) => (
//             <IconButton
//               aria-label='filter'
//               aria-controls='filter-menu'
//               aria-haspopup='true'
//               onClick={handleClick}
//             >
//               <FilterListIcon />
//             </IconButton>
//           )}
//         >
//           {filters.map((filter, index) => (
//             <MenuItem key={index} value={filter.value}>
//               {filter.label}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//       <Menu
//         id='filter-menu'
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleClose}
//       >
//         {filters.map((filter, index) => (
//           <MenuItem
//             key={index}
//             onClick={() => {
//               handleClose();
//               setSelectedFilter(filter.value);
//             }}
//           >
//             {filter.label}
//           </MenuItem>
//         ))}
//       </Menu>

//     </div>*/
//   }
// };

// export default FilterComponent;

const FilterComponent = ({
  title,
  data,
  selectedValue,
  onSelection,
  ...rest
}) => {
  const handleFilterChange = (event, value) => {
    if (value !== null && value !== undefined) {
      onSelection(value);
    }
  };
  return (
    <Autocomplete
      fullWidth
      disablePortal
      value={selectedValue}
      onChange={handleFilterChange}
      options={data}
      style={{ borderRadius: 5, border: 'none', boxShadow: '0px 1px 0px 1px #ccc'}}
      renderInput={(params) => <TextField {...params} label={title} />}
      {...rest}
    />
  );
};
export default FilterComponent;
