import { Route, Routes } from 'react-router-dom';
import './App.css';
import PendingMembers from './pages/PendingMembers';
import LoginPage from './pages/LoginPage';
import MembersList from './pages/MembersList';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './PrivateRoute';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<LoginPage />} />
        {/* <Route path='/pending-members' element={<PendingMembers />} /> */}
        {/* <Route path='/members-list' element={<MembersList />} /> */}
        <Route
          path='/members-list'
          element={
            <PrivateRoute>
              <MembersList />
            </PrivateRoute>
          }
        />
        <Route
          path='/pending-members'
          element={
            <PrivateRoute>
              <PendingMembers />
            </PrivateRoute>
          }
        />
      </Routes>
      <ToastContainer position='top-right' />
    </>
  );
}

export default App;
