import React, { createContext, useState, useEffect } from 'react';
import { getLocalStorageItem } from './utils/localStorageUtils';

export const AuthContext = createContext({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
});

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  console.log('storedToken', isAuthenticated);

  // Fetch authentication status from local storage or API (replace with your logic)
  useEffect(() => {
    const storedToken = getLocalStorageItem('accessToken');
    console.log('storedToken', storedToken);
    setIsAuthenticated(!!storedToken);
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
