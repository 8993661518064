export const toNormalCase = (text) => {
  return text.toLowerCase().replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
};

export const statusBgColor = (status) => {
  let bgColor = 'bg-yellow-500';
  switch (status) {
    case 'APPROVED':
      bgColor = 'bg-green-500';
      break;
    case 'FEE AWAITED':
      bgColor = 'bg-gray-500';
      break;
    case 'DECLINE':
      bgColor = 'bg-red-500';
      break;
    case 'PENDING':
      bgColor = 'bg-yellow-500';
      break;
    default:
  }
  return bgColor;
};
