import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { MemberProvider } from './context/MemberContext';
import CombineProviders from './context/CombineProviders';

const root = ReactDOM.createRoot(document.getElementById('root'));

//Here add multiple providers
const providers = [MemberProvider];
root.render(
  <BrowserRouter>
    <div className='mx-auto'>
      <CombineProviders providers={providers}>
        <App />
      </CombineProviders>
      ,
    </div>
  </BrowserRouter>
);

reportWebVitals();
