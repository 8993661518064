import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
  },
  title: {
    fontSize: 20,
    marginBottom: 10,
    textAlign: 'center',
  },
  section: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  row: {
    flexDirection: 'row',
  },
  cell: {
    padding: 5,
    fontSize: 12,
  },
});

const PDFDocument = ({ data }) => {
  return (
    <Document>
      <Page size={{ width: 'A4', height: 'A4' }} style={styles.page}>
        <Text style={styles.title}>Personal Information</Text>
        <View style={styles.section}>
          <View style={[styles.row, styles.heading]}>
            <Text style={styles.cell}>Name</Text>
            <Text style={styles.cell}>Full Family Name</Text>
            <Text style={styles.cell}>Father's Name</Text>
            <Text style={styles.cell}>Unique No</Text>
            {/* Add more headers here */}
          </View>
          {data.map((item, index) => (
            <View key={index} style={styles.row}>
              <Text style={styles.cell}>{item.name}</Text>
              <Text style={styles.cell}>{item.fullFamilyName}</Text>
              <Text style={styles.cell}>{item.fatherName}</Text>
              <Text style={styles.cell}>{item.uniqueNo}</Text>
              {/* Add more data cells here */}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default PDFDocument;
