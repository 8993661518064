import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { SERVER_CONFIG } from '../utils/config';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

const ViewImageAndPdf = ({ items }) => {
  console.log('items', items);

  const [images, setImages] = useState([]);
  const [pdf, setPdf] = useState([]);

  useEffect(() => {
    const pdfList = [];
    const imageList = [];

    items.forEach((data) => {
      const correctedPath = data.file.replace(/\\/g, '/');
      let doc = { file: data.file, originalName: data.originalName };
      if (correctedPath.endsWith('.pdf')) {
        pdfList.push(doc);
      } else if (correctedPath.match(/\.(jpg|jpeg|png)$/i)) {
        imageList.push(doc);
      }
    });

    setPdf((prevPdf) => [...prevPdf, ...pdfList]);
    setImages((prevImages) => [...prevImages, ...imageList]);
  }, [items]);

  const ShowImages = ({ img }) => {
    return (
      <div className='flex flex-wrap gap-5'>
        {img.map((item) => (
          <Button
            className='text-black text-left'
            style={{
              backgroundColor: '#f23b52',
              color: '#fff',
              fontWeight: 'bold',
              gap: 16,
              fontSize: '12px',
            }}
            size='small'
            startIcon={<ImageOutlinedIcon />}
            variant='contained'
            onClick={() =>
              window.open(`${SERVER_CONFIG.SERVER_IP_WGGV}/${item.file}`, '_blank')
            }
          >
            {item.originalName}
          </Button>
        ))}
      </div>
    );
  };

  const ShowPDF = ({ document }) => {
    return (
      <div className='flex flex-wrap gap-5'>
        {document.map((item) => (
          <Button
            style={{
              backgroundColor: '#333',
              color: '#fff',
              gap: 16,
              fontSize: '12px',
            }}
            size='small'
            variant='contained'
            startIcon={<PictureAsPdfOutlinedIcon />}
            onClick={() =>
              window.open(`${SERVER_CONFIG.SERVER_IP_WGGV}/${item.file}`, '_blank')
            }
          >
            {item.originalName}
          </Button>
        ))}
      </div>
    );
  };

  return (
    <div
      className={`mx-auto ${
        images?.length > 0 &&
        pdf?.length > 0 &&
        'grid grid-cols-1 lg:grid-cols-2'
      } gap-8 items-start mb-8`}
    >
      {images?.length > 0 && <ShowImages img={images} />}
      {pdf?.length > 0 && <ShowPDF document={pdf} />}
    </div>
  );
};

export default ViewImageAndPdf;
