import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from '@mui/material';
import { TextAreaInputField } from './TextInputField';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import toastify from './Toastify';
import axios from 'axios';
import { SERVER_CONFIG } from '../utils/config';
import {
  useMember,
  UPDATE_MEMBER_STATUS_AND_FEEDBACK,
} from '../context/MemberContext';

// Define Yup validation schema
const validationSchema = Yup.object().shape({
  status: Yup.string().required('Status is required'),
  feedback: Yup.string(), // Add validation rules for feedback if needed
});

const statusOptions = [
  { label: 'Pending', value: 'PENDING' },
  { label: 'Fee Awaited', value: 'FEE AWAITED' },
  { label: 'Completed Approval', value: 'APPROVED' },
  { label: 'Declined', value: 'DECLINE' },
];

const UpdateStatusAndFeedback = ({ data, onClose }) => {
  const { dispatch } = useMember();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  console.log('data ffddf', data);
  const initialValues = {
    status: data.status || '',
    feedback: data.feedback || '',
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      setError(null);
      setSuccess(false);

      // Update request
      const response = await axios.put(
        `${SERVER_CONFIG.SERVER_IP_WGGV}/api/v1/member/${data.id}`,
        values,
        {
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers as needed
          },
        }
      );
      console.log('response', response);

      if (response.status === 201) {
        await dispatch({
          type: UPDATE_MEMBER_STATUS_AND_FEEDBACK,
          payload: {
            id: data.id,
            status: values.status,
            feedback: values.feedback,
          },
        });
        setSuccess(true);
        toastify('SUCCESS', 'Form updated successfully!', 'alertmsg');
        resetForm(); // Reset the form
        onClose();
      }
    } catch (error) {
      console.error('Error updating request:', error.message);
      setError(error.message);
      toastify('ERROR', 'Failed to update request', 'alertmsg');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1 className='text-3xl  font-bold my-12 text-center'>Current status</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form>
            <div className='mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 items-start mb-8'>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  name='status'
                  label='Status'
                  value={values.status}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue('status', e.target.value);
                  }}
                  error={errors.status && touched.status}
                >
                  <MenuItem value=''>Select</MenuItem>
                  {statusOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.status && touched.status && (
                  <div className='text-red-500'>{errors.status}</div>
                )}
              </FormControl>

              <TextAreaInputField
                style={{ width: '100%' }}
                title={'Feedback'}
                isOptional={true}
                value={values.feedback}
                onChangeText={(text) => setFieldValue('feedback', text)}
              />
            </div>
            <div className='flex justify-center mt-8 gap-5'>
              <Button
                style={{
                  borderColor: '#333',
                  color: '#333',
                  cursor: 'pointer',
                }}
                onClick={onClose}
                variant='outlined'
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: isSubmitting || loading ? '#fff' : '#333',
                  color: '#fff',
                  cursor: 'pointer',
                }}
                type='submit'
                variant='contained'
                disabled={isSubmitting || loading}
              >
                {loading ? <CircularProgress size={28} /> : 'Submit'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateStatusAndFeedback;
