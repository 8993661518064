// MemberContext.js
import React, { createContext, useContext, useReducer } from 'react';

const MemberContext = createContext();

export const useMember = () => useContext(MemberContext);
export const UPDATE_MEMBER_STATUS_AND_FEEDBACK =
  'UPDATE_MEMBER_STATUS_AND_FEEDBACK';

export const DELETE_MEMBER = 'DELETE_MEMBER';

const initialState = {
  members: [],
  status: '',
};

const memberReducer = (state, action) => {
  switch (action.type) {
    case 'SET_MEMBERS':
      return {
        ...state,
        members: [...state.members, ...action.payload],
      };
    case 'ADD_MEMBER':
      return {
        ...state,
        members: [...state.members, action.payload],
      };
    case 'UPDATE_MEMBER':
      return {
        ...state,
        members: state.members.map((member) =>
          member.id === action.payload.id ? action.payload : member
        ),
      };
    case 'DELETE_MEMBER':
      return {
        ...state,
        members: state.members.filter(
          (member) => member.id !== action.payload.id
        ),
      };
    case 'EMPTY_MEMBERS':
      return {
        ...state,
        members: [],
      };
    case UPDATE_MEMBER_STATUS_AND_FEEDBACK:
      const updatedMembers = state?.members?.map((member) =>
        member.id === action.payload.id
          ? {
              ...member,
              status: action.payload.status,
              feedback: action.payload.feedback,
            }
          : member
      );
      const filteredMembers =
        state.status !== '' && state.status !== null
          ? updatedMembers.filter((member) => member.status === state.status)
          : updatedMembers;

      return {
        ...state,
        members: filteredMembers,
      };
    case 'SET_MEMBERS_STATUS':
      return {
        ...state,
        status: action.payload,
      };
    default:
      return state;
  }
};

export const MemberProvider = ({ children }) => {
  const [state, dispatch] = useReducer(memberReducer, initialState);

  return (
    <MemberContext.Provider value={{ state, dispatch }}>
      {children}
    </MemberContext.Provider>
  );
};
