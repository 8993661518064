import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ContextMenu = ({ actions, itemInfo }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-controls='context-menu'
        aria-haspopup='true'
        onClick={handleClick}
        size='small'
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='context-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions.map((action, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleClose();
              if (!action.disabled) {
                action.handler(itemInfo, action.label);
              }
            }}
            disabled={action.disabled || false}
          >
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ContextMenu;
