import React, { useRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@mui/material';
import RadioButtonsGroup from './RadioButtonsGroup';
import CancelIcon from '@mui/icons-material/Cancel';
import { NumberInputField, TextAreaInputField } from './TextInputField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ViewImageAndPdf from './ViewImageAndPdf';
import UpdateStatusAndFeedback from './UpdateStatusAndFeedback';
import UserProfileImage from './UserProfileImage';

const baseUrl = process.env.API_URL;
function RegistrationForm({ data, onOpen, onClose }) {
  console.log('data', data);
  const formModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '80%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#fff',
    boxShadow: 24,
    borderRadius: 6,
    border: 'none',
  };

  const textFieldStyles = {
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: '#000000',
    },
  };

  const statusOptions = [
    { label: 'Pending', value: 'PENDING' },
    { label: 'Fee Awaited', value: 'FEE AWAITED' },
    { label: 'Completed Approval', value: 'APPROVED' },
    { label: 'Declined', value: 'DECLINE' },
  ];

  const handleCancel = () => {};

  return (
    <Modal style={{ zIndex: 999 }} open={onOpen} onClose={onClose}>
      <Box sx={formModalStyle} className='w-[90%] md:w-[70%] p-2 md:p-8'>
        <div className='registrationModal relative'>
          <div className='fixed top-0 right-0 sm:p-4 lg:p-4'>
            <CancelIcon
              className='cursor-pointer'
              onClick={() => {
                onClose();
              }}
              style={{ color: 'red' }}
            />
          </div>
          <h1 className='text-3xl  font-bold mb-12 text-center'>
            Membership details
          </h1>
          <div className='flex text-gray-500 items-center justify-center -mt-4'>
            <p>
              In this template,can only edit the status and feedback sections,
              while the rest of the fields remain non-editable
            </p>
          </div>
          {/* <form> */}
          <h1 className='text-3xl  font-bold my-12 text-center'>
            User details
          </h1>

          <div className='mx-auto flex items-center justify-center p-2 m-2'>
            <UserProfileImage
              onUpload={(file) => {}}
              onRemove={() => {}}
              path={data?.profileImage}
            />
          </div>

          <div className='mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 items-start'>
            <TextField
              label={'Name as per Aadhar'}
              variant='outlined'
              fullWidth
              value={data?.name}
              disabled
              sx={textFieldStyles}
            />

            <TextField
              label={'Full family name'}
              variant='outlined'
              fullWidth
              value={data?.fullFamilyName}
              disabled
              sx={textFieldStyles}
            />

            <TextField
              label={'Father name'}
              variant='outlined'
              fullWidth
              value={data?.fatherName}
              disabled
              sx={textFieldStyles}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={'Date of birth'}
                value={dayjs(data.dateOfBirth)}
                disabled
                disableFuture
                sx={textFieldStyles}
              />
            </LocalizationProvider>

            <RadioButtonsGroup
              label={'Gender'}
              selectedValue={'Male'}
              data={['Male']}
              disableAll
              sx={textFieldStyles}
            />

            <TextField
              label={'Present Address'}
              variant='outlined'
              fullWidth
              value={data?.presentAddress}
              disabled
              sx={textFieldStyles}
            />

            <TextField
              label={'Permanent Address'}
              variant='outlined'
              fullWidth
              value={data?.permanentAddress}
              disabled
              sx={textFieldStyles}
            />

            <TextField
              label={'Phone Number'}
              variant='outlined'
              fullWidth
              value={data?.cellNo}
              disabled
              sx={textFieldStyles}
            />

            <TextField
              label={'WhatsApp Number'}
              variant='outlined'
              fullWidth
              value={data?.whatsappNo}
              disabled
              sx={textFieldStyles}
            />

            <TextField
              label={'Email ID'}
              variant='outlined'
              fullWidth
              value={data?.emailId}
              disabled
              sx={textFieldStyles}
            />

            <TextField
              label={'Qualification'}
              variant='outlined'
              fullWidth
              value={data?.qualification}
              disabled
              sx={textFieldStyles}
            />

            <TextField
              label={'Profession'}
              variant='outlined'
              fullWidth
              value={data?.profession}
              disabled
              sx={textFieldStyles}
            />
          </div>

          <h1 className='text-3xl  font-bold my-12 text-center'>
            {' '}
            Educational Details
          </h1>
          <div className='mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 items-start'>
            <TextField
              label={'Elementary school year'}
              variant='outlined'
              fullWidth
              value={data?.elementarySchoolYear}
              disabled
              sx={textFieldStyles}
            />

            <TextField
              label={'Higher Secondary School Year'}
              variant='outlined'
              fullWidth
              value={data?.highSchoolYear}
              disabled
              sx={textFieldStyles}
            />

            <TextField
              label={'Graduation Year'}
              variant='outlined'
              fullWidth
              value={data?.graduationYear}
              disabled
              sx={textFieldStyles}
            />

            <TextField
              label={'Post Graduation Year'}
              variant='outlined'
              fullWidth
              value={data?.postGraduationYear}
              disabled
              sx={textFieldStyles}
            />

            <TextField
              label={'Doctorate Subject [Ph.D] Year'}
              variant='outlined'
              fullWidth
              value={data?.doctorateSubjectYear}
              disabled
              sx={textFieldStyles}
            />
          </div>

          <h1 className='text-3xl  font-bold my-12 text-center'>
            Family and Relation Details
          </h1>
          <div className='mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 items-start'>
            <TextField
              label={'Paternal Grand Father'}
              placeholder={'Full Family name'}
              variant='outlined'
              fullWidth
              value={data?.paternalGrandfatherFullFamilyName}
              disabled
              sx={textFieldStyles}
            />

            <TextField
              label={'Maternal Grand Father'}
              placeholder={'Full Family name'}
              variant='outlined'
              fullWidth
              value={data?.maternalGrandfatherFullFamilyName}
              disabled
              sx={textFieldStyles}
            />

            <RadioButtonsGroup
              label={'Marital Status'}
              selectedValue={
                data?.maritalStatus == 'Married' ? 'Married' : 'Unmarried'
              }
              data={['Married', 'Unmarried']}
              disableAll
              sx={textFieldStyles}
            />

            <TextField
              label={'Father-in-Law'}
              placeholder={'Full Family name'}
              variant='outlined'
              fullWidth
              value={data?.fatherInLawFullFamilyName}
              disabled
              sx={textFieldStyles}
            />
          </div>

          <h1 className='text-3xl  font-bold mt-12 mb-6 text-center'>
            Professional Details
          </h1>
          <p className='text-center mb-12'>
            If worked in Vaniyambadi institutions and Office for Non native of
            Vaniyambadi{' '}
          </p>
          <div className='mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 items-start'>
            <TextField
              label={'Name of the Institution or Office'}
              variant='outlined'
              fullWidth
              value={data?.institutionsOrOfficeName}
              disabled
              sx={textFieldStyles}
            />

            <TextField
              label={'Work Start Year'}
              variant='outlined'
              fullWidth
              value={data?.yearOfWork === 'null' ? '' : data?.yearOfWork}
              disabled
              sx={textFieldStyles}
            />
          </div>

          <h1 className='text-3xl  font-bold my-12 text-center'>Referred by</h1>
          <div className='mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 items-start'>
            <TextField
              fullWidth
              isOptional={true}
              label={'Person 1 Name'}
              value={data?.personOneName}
              disabled
              sx={textFieldStyles}
            />

            <NumberInputField
              title={'Person 1 Phone number'}
              isOptional={true}
              isMobile={true}
              value={data?.personOnePhone}
              disabled
              sx={textFieldStyles}
            />
            <TextField
              fullWidth
              isOptional={true}
              label={'Person 2 Name'}
              value={data?.personTwoName}
              disabled
              sx={textFieldStyles}
            />

            <NumberInputField
              title={'Person 2 Phone number'}
              isOptional={true}
              isMobile={true}
              value={
                data?.personTwoPhone === 'null' ? '' : data?.personTwoPhone
              }
              disabled
              sx={textFieldStyles}
            />
          </div>

          {/* <h1 className='text-3xl  font-bold my-12 text-center'>
              Current status
            </h1>
            <div className='mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 items-start mb-8'>
              <FormControl>
                <InputLabel>Status</InputLabel>
                <Select value={''} label='Status'>
                  {statusOptions.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextAreaInputField
                style={{ width: '100%' }}
                title={'Feedback'}
                isOptional={true}
                value={''}
                onChangeText={(text) => console.log('text', text)}
              />
            </div> */}

          {data && data?.memberFiles && data?.memberFiles.length > 0 && (
            <div>
              <h1 className='text-3xl  font-bold my-12 text-center'>
                Member documents
              </h1>
              <ViewImageAndPdf items={data?.memberFiles} />
            </div>
          )}
          <div>
            <UpdateStatusAndFeedback
              data={data}
              onClose={onClose}
            />
          </div>
          {/* <div className='flex justify-center mt-8 gap-5'>
              <Button
                style={{
                  borderColor: '#333',
                  color: '#333',
                  cursor: 'pointer',
                }}
                onClick={onClose}
                variant='outlined'
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: '#333',
                  color: '#fff',
                  cursor: 'pointer',
                }}
                type='submit'
                variant='contained'
              >
                Save
              </Button>
            </div> */}
          {/* </form> */}
        </div>
      </Box>
    </Modal>
  );
}

export default RegistrationForm;
