import { toast } from "react-toastify"

export default (status, message, customId = "toastId", duration = 3000) => {
  const toastId = customId ?? "toastId"
  if (status === "SUCCESS") {
    return toast.success(message || "All is good", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: { backgroundColor: "#189b77" },
    })
  } else if (status === "ERROR") {
    return toast.error(message?.toString() || "Something went wrong", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: { backgroundColor: "#e20000" },
    })
  } else if (status === "INFORMATIONAL") {
    return toast.info(message, {
      position: "top-center",
      autoClose: duration,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: { backgroundColor: "#0288d1" },
    })
  } else {
    return {}
  }
}
