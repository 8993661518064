import React from 'react';
import { Button, Modal, Typography, Box } from '@mui/material';

const ConfirmationModal = ({
  isOpen,
  title,
  message,
  onClose,
  onConfirm,
  deleteLoading,
}) => {
  const formModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    bottom: '10%',
    height: '25%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#fff',
    boxShadow: 24,
    borderRadius: 6,
    border: 'none',
  };
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={formModalStyle} className='w-[30%] md:w-[30%] p-2 md:p-8 mb-4'>
        <Typography variant='h6'>{title}</Typography>
        <Typography variant='body1'>{message}</Typography>
        <div className='flex items-start justify-evenly my-4'>
          <Button
            onClick={onConfirm}
            variant='contained'
            color='error'
            disabled={deleteLoading}
          >
            {deleteLoading ? 'Loading...' : 'Confirm'}
          </Button>
          <Button onClick={onClose} variant='contained' color='info'>
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
